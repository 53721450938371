import React, { useState, useRef } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.css";
import LayoutWidget from "./LayoutWidget";

const SwiperWidget = ({ data }) => {
  const swiperRef = useRef(null);
  const [activeTab, setActiveTab] = useState(0);

  const handleSlideChange = (index) => {
    setActiveTab(index);
    if (swiperRef.current && swiperRef.current.swiper) {
      swiperRef.current.swiper.slideTo(index);
    }
  };

  return (
    <div>
      <div className="nav2">
        {data?.children?.map((item, index) => (
          <div
            onClick={() => handleSlideChange(index)}
            className={`nav-item ${activeTab === index ? "active" : ""}`}
            key={index}
          >
            {item.title}
          </div>
        ))}
      </div>
      <Swiper ref={swiperRef} className="swiper-container">
        {data?.children?.map((item, index) => (
          <SwiperSlide key={index}>
            {item?.children.map((e, index) => (
              <LayoutWidget data={e} key={index} />
            ))}
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};
export default SwiperWidget;
