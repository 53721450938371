import axios from "axios";

// 创建 Axios 实例
const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL || "https://api.example.com", // 基础 URL
  timeout: 10000, // 请求超时 10 秒
  headers: {
    "Content-Type": "application/json",
  },
});

// 请求拦截器
axiosInstance.interceptors.request.use(
  (config) => {
    // 在请求头中添加 Token
    const token = localStorage.getItem("token");
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    // 请求错误处理
    return Promise.reject(error);
  }
);

// 响应拦截器
axiosInstance.interceptors.response.use(
  (response) => response.data, // 只返回数据部分
  (error) => {
    // 在这里处理响应错误
    if (error.response) {
      const { status, data } = error.response;

      // 统一处理 401 未授权错误
      if (status === 401) {
        localStorage.removeItem("token");
        window.location.href = "/login"; // 跳转到登录页面
      }

      // 将错误信息返回
      return Promise.reject(data);
    }
    return Promise.reject(error.message || "Network Error");
  }
);

export default axiosInstance;
