import React from "react";
import TextWidget from "./TextWidget";
import LfAdWidget from "./LfAdWidget";
import AppWidget from "./AppWidget";
import LfAd2Widget from "./LfAd2Widget";
import WaterfallWidget from "./WaterfallWidget";
import SwiperWidget from "./SwiperWidget";
import LiveWidget from "./LiveWidget";

const LayoutWidget = ({ data }) => {
  return (
    <div>
      {data.component === "text" && data.data && <TextWidget data={data} />}
      {data.component === "lfAd" && <LfAdWidget data={data} />}
      {data.component === "lfAd2" && data.data && <LfAd2Widget data={data} />}
      {data.component === "app" && data.data && <AppWidget data={data} />}
      {data.component === "live" && data.data && <LiveWidget data={data} />}
      {data.component === "swiper" && data.data && <SwiperWidget data={data} />}
      {data.component === "hook" && <WaterfallWidget data={data} />}
    </div>
  );
};

export default LayoutWidget;
