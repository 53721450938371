import React from "react";

function TextWidget({ data }) {
  return (
    <div className="ad-text-wrap">
      {data.data.map((item, index) => (
        <a key={index} className="ad-text-item h text" target="_blank" href={item.url} i="0">
          <img className="ad-text-icon" src={"/h-icon.svg"} alt={item.name} />
          <div className="text">{item.name}</div>
        </a>
      ))}
    </div>
  );
}

export default TextWidget;
