const setRem = (designWidth = 800, maxWidth = 500) => {
  const updateRem = () => {
    const html = document.documentElement;
    let screenWidth = html.getBoundingClientRect().width;

    if (screenWidth > maxWidth) {
      screenWidth = maxWidth; // 限制最大宽度
    }

    const rem = (100 * screenWidth) / designWidth; // 计算 rem 比例
    let r = document.createElement("style");
    r.innerHTML = "html{font-size:" + rem + "px;}";
    html.style.fontSize = `${rem}px`; // 设置根元素的 font-size
  };

  updateRem(); // 初始化 rem

  // 监听窗口大小变化
  window.addEventListener(
    "resize",
    () => {
      clearTimeout(window.remResizeTimer);
      window.remResizeTimer = setTimeout(updateRem, 300);
    },
    false
  );

  // 页面缓存时处理 rem
  window.addEventListener(
    "pageshow",
    (event) => {
      if (event.persisted) {
        clearTimeout(window.remResizeTimer);
        window.remResizeTimer = setTimeout(updateRem, 300);
      }
    },
    false
  );
};

export default setRem;
